import useSSR from 'hooks/useSSR';
import * as React from 'react';
import { Link } from 'gatsby';
import { Trans, useTranslation } from 'react-i18next';

// styles
const pageStyles = {
    color: '#232129',
    padding: '96px',
    fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
};

const paragraphStyles = {
    marginBottom: 48,
};

type Props = {
    location: {
        pathname: string;
        search?: string;
    };
};

// markup
const NotFoundPage = (props: Props): React.ReactElement => {
    const { browser } = useSSR();
    if (browser) {
        if (!(props.location.search || '').includes('redirect=true')) {
            const prefix = props.location.pathname.startsWith('/locations')
                ? 'locations'
                : props.location.pathname.startsWith('/rentals')
                ? 'rentals'
                : null;
            if (prefix) {
                const parts = props.location.pathname.split('/');
                if (parts.length > 2) {
                    const slug = parts[parts.length - 1];
                    window.location.href = `/${prefix}/${slug}?redirect=true`;
                }
            }
        }
    }

    const { t } = useTranslation('layout');

    return (
        <main style={pageStyles}>
            <title>
                <Trans t={t}>layout:404.title</Trans>
            </title>
            <h1 style={headingStyles}>
                <Trans t={t}>layout:404.h1</Trans>
            </h1>
            <p style={paragraphStyles}>
                <Trans t={t}>layout:404.content</Trans>
                <br />
                <Link to="/">
                    <Trans t={t}>layout:404.home</Trans>
                </Link>
            </p>
        </main>
    );
};

export default NotFoundPage;
